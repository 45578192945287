<ng-container *transloco="let t; read: 'customize-dashboard-modal'">
  <div class="modal-header">
    <h4 class="modal-title">{{t('title-' + activeTab)}}</h4>
    <button type="button" class="btn-close" [attr.aria-label]="t('close')" (click)="close()"></button>
  </div>
  <div #modalBody class="modal-body scrollable-modal {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? '' : 'd-flex'}}">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-pills" orientation="{{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'horizontal' : 'vertical'}}" style="min-width: 154px;">
      <li [ngbNavItem]="TabID.Dashboard">
        <a ngbNavLink>{{t(TabID.Dashboard)}}</a>
        <ng-template ngbNavContent>
          <app-customize-dashboard-streams></app-customize-dashboard-streams>
        </ng-template>
      </li>

      <li [ngbNavItem]="TabID.SideNav">
        <a ngbNavLink>{{t(TabID.SideNav)}}</a>
        <ng-template ngbNavContent>
          <app-customize-sidenav-streams></app-customize-sidenav-streams>
        </ng-template>
      </li>
      <li [ngbNavItem]="TabID.SmartFilters">
        <a ngbNavLink>{{t(TabID.SmartFilters)}}</a>
        <ng-template ngbNavContent>
          <app-manage-smart-filters></app-manage-smart-filters>
        </ng-template>
      </li>
      <li [ngbNavItem]="TabID.ExternalSources">
        <a ngbNavLink>{{t(TabID.ExternalSources)}}</a>
        <ng-template ngbNavContent>
          <app-manage-external-sources></app-manage-external-sources>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tab-content {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'mt-3' : 'ms-4 flex-fill'}}"></div>
  </div>

  <div class="modal-footer">
    <a class="btn btn-icon" href="https://wiki.kavitareader.com/en/guides/customization" target="_blank" rel="noopener noreferrer">{{t('help')}}</a>
    <button type="button" class="btn btn-primary" (click)="close()">{{t('close')}}</button>
  </div>
</ng-container>

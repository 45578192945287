<ng-container *ngIf="link === undefined || link?.length === 0; else useLink">
    <div class="side-nav-item" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </div>
</ng-container>

<ng-template #useLink>
    <ng-container *ngIf="external; else internal">
        <a class="side-nav-item" [href]="link" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}" rel="noopener noreferrer" target="_blank">
            <ng-container [ngTemplateOutlet]="inner"></ng-container>
        </a>
    </ng-container>
    <ng-template #internal>
        <ng-container *ngIf="queryParams && queryParams !== {}; else regInternalLink">
          <a class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}" (click)="openLink()">
            <ng-container [ngTemplateOutlet]="inner"></ng-container>
          </a>
        </ng-container>
      <ng-template #regInternalLink>
        <a class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': (navService.sideNavCollapsed$ | async), 'active': highlighted}" [routerLink]="link" [queryParams]="queryParams">
          <ng-container [ngTemplateOutlet]="inner"></ng-container>
        </a>
      </ng-template>
    </ng-template>

</ng-template>


<ng-template #inner>
    <div class="active-highlight"></div>
    <span class="phone-hidden" title="{{title}}">
        <div>
          <ng-container *ngIf="imageUrl !== null && imageUrl !== ''; else iconImg">
            <app-image [imageUrl]="imageUrl" width="20px" height="20px"></app-image>
          </ng-container>
          <ng-template #iconImg><i class="fa {{icon}}" aria-hidden="true"></i></ng-template>
        </div>
    </span>
    <span class="side-nav-text">
        <div>
            {{title}}
        </div>
    </span>
    <span class="card-actions">
        <ng-content select="[actions]"></ng-content>
    </span>
</ng-template>
